import {
    isIOfflineStepDefinition,
    isIOnlineStepDefinition,
    isIStepTruncateIndexedDB,
    StepDefinition,
    IStepDefinitionOptions,
    IOfflineStepDefinition,
    IOnlineStepDefinition,
    IStepTruncateIndexedDB,
    type ISyncOptions
} from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { GroupProgress, type IGroupProgressOptions, type IGroupProgressJSON } from 'o365.pwa.modules.client.steps.GroupProgress.ts';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { type SyncType } from "o365.pwa.types.ts";
import { DataObjectStepDefinition } from 'o365.pwa.modules.client.steps.DataObjectStepDefinition.ts';
import { getDataObjectById, type DataObject } from 'o365-dataobject';
import { app } from 'o365-modules';
import { getOrCreateProcedure } from 'o365-modules';
import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

export interface IGroupStepDefinitionOptions extends IStepDefinitionOptions {
    steps: Array<StepDefinition>;
    onBeforeSync?: Function;
    onAfterSync?: Function;
}

export class GroupStepDefinition extends StepDefinition implements IOfflineStepDefinition<GroupProgress>, IOnlineStepDefinition<GroupProgress>, IStepTruncateIndexedDB<GroupProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    public readonly IOnlineStepDefinition = 'IOnlineStepDefinition';
    public readonly IStepTruncateIndexedDB = 'IStepTruncateIndexedDB';

    public readonly steps: Array<StepDefinition>;
    public readonly onBeforeSync?: Function;
    public readonly onAfterSync?: Function;

    constructor(options: IGroupStepDefinitionOptions) {
        for (const step of options.steps) {
            if (step instanceof GroupStepDefinition) {
                throw Error('GroupStepDefinition validation failed. Group cannot have a sub group');
            }
        }

        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'GroupProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.GroupProgress.vue');
            },
            subVueComponentsDefinitions: options.steps.map((step) => { return { vueComponentName: step.vueComponentName, vueComponentImportCallback: step.vueComponentImportCallback }; }),
        });

        this.steps = options.steps;
        this.onBeforeSync = options.onBeforeSync;
        this.onAfterSync = options.onAfterSync;
    }

    public generateStepProgress(options?: IGroupProgressOptions | IGroupProgressJSON, syncType?: SyncType): GroupProgress {
        return new GroupProgress({
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback,
            stepsProgress: this.steps.map((step: StepDefinition, index: number) => { return step.generateStepProgress(options?.stepsProgress?.[index], syncType); })
        });
    }
    
    public async syncOnline(options: ISyncOptions<GroupProgress>): Promise<void> {
        await this._runSync(options, async (step: StepDefinition, index: number) => {
            if (isIOnlineStepDefinition(step)) {
                await step.syncOnline({
                    syncProgress: options.syncProgress,
                    stepProgress: options.stepProgress.stepsProgress[index],
                    memory: options.memory
                });
            }
        });
    }

    public async syncOffline(options: ISyncOptions<GroupProgress>): Promise<void> {
        await this._runSync(options, async (step: StepDefinition, index: number) => {
            if (isIOfflineStepDefinition(step)) {
                await step.syncOffline({
                    syncProgress: options.syncProgress,
                    stepProgress: options.stepProgress.stepsProgress[index],
                    memory: options.memory
                });
            }
        });

        try {
            const offlineDataTypes = new Set();
            const offlineDataFileTypes = new Set();

            for (const index in this.steps) {
                const step = this.steps[index];

                if (step instanceof DataObjectStepDefinition) {
                    const dataObject: DataObject = getDataObjectById(step.dataObjectId, app.id);

                    const stepSyncStatus = options.stepProgress.stepsProgress[index].syncStatus;

                    if (![SyncStatus.Syncing, SyncStatus.SyncingComplete].includes(stepSyncStatus)) {
                        continue;
                    }

                    if (step.fileTableType) {
                        offlineDataFileTypes.add(dataObject.offline.objectStoreIdOverride ?? dataObject.id);
                    } else {
                        offlineDataTypes.add(dataObject.offline.objectStoreIdOverride ?? dataObject.id);
                    }
                }
            }

            if (offlineDataTypes.size > 0) {
                const procOfflineDataStatusUpdate = getOrCreateProcedure({ id: 'procOfflineDataStatusUpdate', procedureName: 'sstp_System_OfflineDataStatusUpdateOnTypes' });
                const device = await IndexedDbHandler.getUserDevice();

                await procOfflineDataStatusUpdate.execute({
                    DeviceRef: device?.deviceRef,
                    AppID: app.id,
                    Types: [...offlineDataTypes.values()].map((value) => [value]),
                });
            }

            if (offlineDataFileTypes.size > 0) {
                const procOfflineDataFilesStatusUpdate = getOrCreateProcedure({ id: 'procOfflineDataFilesStatusUpdate', procedureName: 'sstp_System_OfflineDataFilesStatusUpdateOnTypes' });
                const device = await IndexedDbHandler.getUserDevice();

                await procOfflineDataFilesStatusUpdate.execute({
                    DeviceRef: device?.deviceRef,
                    AppID: app.id,
                    Types: [...offlineDataFileTypes.values()].map((value) => [value]),
                });
            }
        } catch (error: any) {
            options.stepProgress.errors.push(error);
            options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', ''));
            options.stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
        }
    }

    public async truncateData(options: ISyncOptions<GroupProgress>): Promise<void> {
        await this._runSync(options, async (step: StepDefinition, index: number) => {
            if (isIStepTruncateIndexedDB(step)) {
                await step.truncateData({
                    syncProgress: options.syncProgress,
                    stepProgress: options.stepProgress.stepsProgress[index],
                    memory: options.memory
                });
            }
        });
    }

    private async _runSync(options: ISyncOptions<GroupProgress>, runSync: (step: StepDefinition, index: number) => Promise<void>) {
        try {
            const promiseList = new Array<Promise<void>>();

            for (const [index, step] of this.steps.entries()) {
                step.runningInGroup = true;

                options.stepProgress.stepsProgress[index].syncStatus = SyncStatus.Syncing;

                promiseList.push(runSync(step, index));
            }

            const promiseListResult = await Promise.allSettled(promiseList);

            for (const [index, promiseResults] of promiseListResult.entries()) {
                if (promiseResults.status === 'rejected') {
                    options.stepProgress.errors.push(promiseResults.reason);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', ''));

                    if (options.stepProgress.syncStatus < SyncStatus.SyncingCompleteWithErrors) {
                        options.stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
                    }

                    continue;
                }

                const result = options.stepProgress.stepsProgress[index];

                if (options.stepProgress.syncStatus < result.syncStatus) {
                    options.stepProgress.syncStatus = result.syncStatus;
                }
            }
        } catch (error: any) {
            options.stepProgress.errors.push(error);
            options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', ''));
            options.stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
        }
    }
}
